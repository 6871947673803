import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import Hero from "../components/Blocks/Hero"
import Blocks from "../components/Index/NewBlocks"
import {
  DESKTOP_HEADER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
} from "../components/Layout/Layout"
import styled from "styled-components"
import breakpoints from "../styles/breakpoints"
import HomePageShop from "src/components/Blocks/HomePageShop"
import ProductLandingPageBlock from "src/components/Index/ProductLandingPageBlock"
import colors from "../styles/colors"
import CityTicker from "src/components/Index/CityTicker"
import DeliveryZone from "../components/Index/DeliveryZone"
import fonts from "src/styles/fonts"
import { Link } from "gatsby"

const CityTicker2 = styled(CityTicker)`
  display: none;
  @media (max-width: ${breakpoints.md}) {
    display: block;
  }
`
const HeadlinesWrapper = styled.div`
  margin-left: 20vw;
  margin-right: 20vw;

  @media (max-width: ${breakpoints.md}) {
    margin-left: 2em;
    margin-right: 2em;
  }
`
const Headlines = styled.div`
  display: flex;
  justify-content: center;
  color: ${colors.royalBlue};
`

const Headlines2 = styled.div`
  display: flex;
  justify-content: center;
  color: ${colors.royalBlue};
  padding-bottom: 15px;
`

const ProductBlockWrapper = styled.div`
    height: auto;
    margin-top: 50px;
    margin-bottom:50px;
  
  }

  @media (max-width: ${breakpoints.md}) {
    
  }
`

const ProductsSubHeadline = styled.h3`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: calc(0.8vw + 1rem);
  line-height: 112%;
  /* or 40px */
  margin-bottom: 0.5rem;
margin-top: 2rem;
  text-align: center;
  letter-spacing: -0.01em;
  font-feature-settings: "ordn" on, "ss09" on, "ss10" on, "ss13" on;
  font-weight: 900;
  text-transform: capitalize;
  color: rgb(235, 156, 195);
`

const ProductsHeadline = styled.h3`
  ${fonts.workSansBold};
  font-style: bold;
  font-weight: 900;
  font-size: calc(1.5vw + 1rem);
  line-height: 112%;
  /* or 72px */
  padding: 0px 6vw;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;
  margin-bottom: 20px;
  ${"" /* text-transform: uppercase; */}
  font-feature-settings: "ordn" on, "ss09" on, "ss10" on, "ss13" on;
  @media (min-width: 1600px) {
    padding: 0 96px;
  }
`

const ButtonContainer = styled.div`
  ${"" /* margin-top: 34px; */}
  display: flex;
  align-content: center;
  justify-content: center;
  padding-bottom: 2rem;
`
const InternalLink = styled(Link)`

  filter: drop-shadow(3.16071px 3.16071px 0px rgba(0, 0, 0, 0.25));
  
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.25);
  border-radius: 300px;
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.25);
  
  border-width: 2px;
  border-style: solid;
  transition: 0.1s background-color linear, 0.1s color linear;
  padding: .4em 1.8em;

  text-transform: uppercase;
  text-decoration: none;
  background: transparent;
=  font-size: calc(1vw + 12px);
  line-height: 3vw;
  cursor: pointer;
  display: inline-block;
  background: #319AD5;
  border-color:  #319AD5;
  color: white;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 900;
  @media ( min-width: 1600px)
  {
    font-size: calc(16px + 12px);
    line-height: 48px;
  }
  @media (max-width: ${breakpoints.md}) {
    font-size: 18px;
    bottom: 14px;
    position: relative;
  }
`
const HeroContainer = styled.div`
  ${
    "" /* padding-top: ${MOBILE_HEADER_HEIGHT}px;
 

  @media (min-width: ${breakpoints.md}) {
    padding-top: ${DESKTOP_HEADER_HEIGHT}px;
  } */
  }
`

const NewIndexPage = (props) => {
  const { location, data } = props
  const path = location.pathname

  const page = data.contentfulPage

  const contentfulProducts = page.homePageShop.products
  const shopHeading = page.homePageShop.heading
  const drops = page.homePageShop.drops
  const shopSubHeading = page.homePageShop.subHeading
  // const cityTicker = page.tickerImage.file.url
  // const cityTicker = page.tickerImage;
  const icons = {
    vegetarian: data.contentfulShopPage.vegetarianIcon,
    gluten: data.contentfulShopPage.glutenIcon,
  }
  let sharingImage = false
  if (page.socialMediaImage) {
    sharingImage = page.socialMediaImage.file.url
  }

  return (
    <Layout bg={colors.white}>
      <Seo
        title={page.metaTitle}
        description={page.metaDescription}
        fullTitle={true}
        path={path}
        image={sharingImage}
      />{" "}
      {page.hero.video ? (
        <HeroContainer>
          <Hero heroData={page.hero} />{" "}
        </HeroContainer>
      ) : (
        <Hero heroData={page.hero} />
      )}{" "}
      {/* <CityTicker ticker={cityTicker}> </CityTicker>{" "} */}
      {/* <HomePageShop 
                    shopHeading={shopHeading}
                    shopSubHeading={shopSubHeading}
                    contentfulProducts={contentfulProducts} />
                     */}{" "}
      {/* <CityTicker
        {...page.blocks.filter(
          (x) => x.__typename === "ContentfulTickerBlock"
        )[0]}
      /> */}
      {drops ? (
        <>
          <ProductBlockWrapper>
            <HeadlinesWrapper>
              <Headlines>
            
              </Headlines>{" "}
              <Headlines2>
                <ProductsHeadline> {shopHeading} </ProductsHeadline>{" "}
              </Headlines2>{" "}
              <ButtonContainer>
                <InternalLink to={"/shop"}>{"SHOP"}</InternalLink>
              </ButtonContainer>
              <ProductsSubHeadline> FEATURED PRODUCTS </ProductsSubHeadline>{" "}
            </HeadlinesWrapper>
            <ProductLandingPageBlock data={data} />{" "}
          </ProductBlockWrapper>{" "}
        </>
      ) : (
        ""
      )}
      <Blocks blocks={page.blocks} />{" "}
    </Layout>
  )
}

export default NewIndexPage

export const pageQuery = graphql`
  query NewIndexQuery {
    contentfulShopPage(slug: { eq: "shop" }) {
      vegetarianIcon {
        file {
          url 
        }
      }
      glutenIcon {
        file {
          url
        }
      }
    }
    contentfulPage(slug: { eq: "new-homepage" }) {
      ...pageFragment
      hero {
        video {
          file {
            url
          }
        }
      }
      homePageShop {
        drops
        subHeading
        heading
        products {
          title
          slug
          hasVegetarian
          isGlutenFree
          servingSize
          newRelease
          spicy
          blocks {
            __typename
            ... on ContentfulRestaurant {
              name
            }
          }
        }
      }
      blocks {
        ... on ContentfulExplainBlock {
          id
          title
          subTitle
          explainDetail {
            subTitle
            title
          }
          backgroundImage {
            gatsbyImageData
            file {
              url
            }
          }
          icon {
            gatsbyImageData
            file {
              url
            }
          }
          ctaLabel
          ctaUrl
          klaviyoCta
          klaviyoFormId
        }
        ... on ContentfulCountdownBlock {
          subHeading
          heading
          countdownDateTime
          chefImage {
            gatsbyImageData
            file {
              url
            }
          }
        }
        ... on ContentfulBenefitsBlock {
          homePageTextImageBlocks {
            subHeading
            heading
            ctaLabel
            ctaLink
            content {
              raw
            }
            media {
              gatsbyImageData(width: 1200)
              description
              file {
                url
              }
            }
          }
        }
        ... on ContentfulZipCodeBlock {
          id
          heading
          subHeading
          icon {
            gatsbyImageData(width: 600)
            file {
              url
            }
          }
          comingSoonImage {
            gatsbyImageData(width: 1200)
          }
          inTheZoneImage {
            gatsbyImageData(width: 1200)
          }
          notInTheZoneImage {
            gatsbyImageData(width: 1200)
          }
          defaultImage {
            gatsbyImageData(width: 1200)
          }
        }
        ... on ContentfulTickerBlock {
          dividers {
            file {
              url
            }
          }
        }
      }
    }
    allSwellProduct(
      filter: {
        
        slug: {
          in: [
            "aaa-shortribs"
            "joselitos-pozole"
            "forage-stew"
            "starseed-kitchari"
            "kettner-tikka"
            "woon-chewynoodles"
          ]
        }
      }
      sort: {fields: price, order: DESC}
    ) {
      edges {
        node {
          price
          currency
          name
          slug
          stock_status
          stock_level
          options {
            values {
              name
              price

              id
            }
            name
          }
          images {
            fileLocal {
              childImageSharp {
                gatsbyImageData(aspectRatio: 0.76)
              }
            }
          }
          categories {
            slug
          }
          sale_price
          sale
        }
      }
    }
  }
`
