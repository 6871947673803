import React from "react"
import styled from "styled-components"
import PageBlock from "../Blocks/PageBlock"
import SimpleBlock from "../Blocks/SimpleBlock"
import PageBlockGroup from "../Blocks/PageBlockGroup"
import TextImageBlock from "./TextImageBlock"
import PressBlock from "../Blocks/PressBlock"
import ExplainBlock from "../Blocks/ExplainBlock"
import BenefitsBlock from "../Blocks/BenefitsBlock"
import CityTicker from "src/components/Index/CityTicker"
import CountdownBlock from "src/components/Index/CountdownBlock"
import DeliveryZone from "src/components/Index/DeliveryZone"

const Blocks = (props) => {
  const blocks = props.blocks

  return (
    <>
      {blocks && blocks.map((block, index) => {
        const { __typename: type } = block

        if (type === "ContentfulSimpleBlock") {
          return <SimpleBlock {...block} key={index} />
        }

        if (type === "ContentfulTextImageBlock") {
          return <TextImageBlock {...block} key={index} />
        }

        if (type === "ContentfulPageBlockGroup") {
          return <PageBlockGroup {...block} key={index} />
        }

        if (type === "ContentfulExplainBlock") {
          return <><ExplainBlock {...block} key={index} /></>
        }

        if (type === "ContentfulPressBlock") {
          return <PressBlock {...block} key={index}  />
        }

        if (type === "ContentfulCountdownBlock") {
          return <CountdownBlock {...block} key={index} />
        }
        if (type === "ContentfulBenefitsBlock") {
          return <BenefitsBlock {...block} key={index}  />
        }

        if (type === "ContentfulTickerBlock") {
          return <CityTicker {...block} key={index}  />
        }

        if (type === "ContentfulZipCodeBlock")
          return <><DeliveryZone {...block}></DeliveryZone></>
      })}
    </>
  )
}

export default Blocks
